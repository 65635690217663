import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useClickOutside } from '@elo-ui/hooks/use-click-outside'
import classNames from 'classnames'
import { EloPopoverTitle, EloPopoverContent } from './components'

import './elo-popover.scss'

export const POPOVER_PLACEMENTS = [
  'top-left',
  'top-center',
  'top-right',
  'bottom-left',
  'bottom-center',
  'bottom-right',
  'right-top',
  'right-center',
  'right-bottom',
  'left-top',
  'left-center',
  'left-bottom',
] as const

export interface PopoverProps {
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
  onClickOutside?: () => void
  placement: (typeof POPOVER_PLACEMENTS)[number]
  title?: string
  children?: React.ReactNode
  showFooter?: boolean
  isSmall?: boolean
  show?: boolean
  content?: React.ReactNode
  footer?: React.ReactNode
}

export const EloPopover: React.FC<PopoverProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClickOutside,
  placement,
  children,
  showFooter,
  title,
  content,
  footer,
  isSmall,
  show,
}) => {
  const [isVisible, setIsVisible] = useState(show)
  const toggleIsVisible = useCallback(() => {
    setIsVisible((isVisible) => !isVisible)
  }, [setIsVisible])
  const popoverClass = classNames('elo-popover', `elo-popover--${placement}`, { 'elo-popover--sm': isSmall })
  const popoverRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsVisible(show)
  }, [show, setIsVisible])

  if (onClickOutside) {
    useClickOutside(popoverRef, onClickOutside)
  }

  return (
    <div
      ref={popoverRef}
      className='elo-popover__wrapper'
      onMouseEnter={onMouseEnter || toggleIsVisible}
      onMouseLeave={onMouseLeave || toggleIsVisible}
    >
      {children}
      {isVisible && (
        <div className={popoverClass}>
          {title && <EloPopoverTitle title={title} />}
          {content && <EloPopoverContent content={content} contentClass='elo-popover__content' />}
          {showFooter && footer && <EloPopoverContent content={footer} contentClass='elo-popover__footer' />}
          <span className='elo-popover__arrow' />
        </div>
      )}
    </div>
  )
}
