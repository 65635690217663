import React from 'react'

import { amountCrossed, withStores } from 'utils/helpers.utils'
import { isWindows } from 'utils/browsers.utils'
import { PricingPlansList } from '@elo-kit/components/pricing-plans-list/PricingPlansList'

const PricingPlansListContainer = (props) => {
  const { currenciesStore, themeStore, experimentsStore } = props
  const { convertToPrice } = currenciesStore
  const {
    paymentPageColor,
    paymentRecommendedBadgeColor,
    paymentRecommendedBadgeTextColor,
    paymentCountdownBgColor,
    paymentCountdownBorderColor,
    paymentCountdownTextColor,
    paymentCountdownTimeColor,
    countdownText,
  } = themeStore.ppTemplate?.theme || {}
  const { layout } = themeStore.ppTemplate || {}

  const newPricingPlanExperiment = experimentsStore.useExperiment('show_new_pricing_plan_50-50')
  const showNewPricingPlanContent = newPricingPlanExperiment.get('isNewPlan', false)

  return (
    <PricingPlansList
      {...props}
      showNewPricingPlanContent={showNewPricingPlanContent}
      {...{
        convertToPrice,
        amountCrossed,
        isWindows,
        paymentPageColor,
        paymentRecommendedBadgeColor,
        paymentRecommendedBadgeTextColor,
        layout,
        paymentCountdownBgColor,
        paymentCountdownBorderColor,
        paymentCountdownTextColor,
        paymentCountdownTimeColor,
        countdownText,
      }}
    />
  )
}

export default withStores(['currenciesStore', 'themeStore', 'experimentsStore'], PricingPlansListContainer)
